import { useRef, useState } from "react";


function Collapsible(props) {

  const [open, setOpen] = useState(false);
  const contentRef = useRef();

  return (
    <div className='collapsible-container'>
      <button className='collapsible-header' title={open? 'Click to close' : 'Click to expand'} onClick={() => {setOpen(!open)}}>
        {props.label}
      </button>
      <div 
        ref={contentRef}
        className={open ? 'collapsible-area-open' : 'collapsible-area-closed'}
        style={open ? { height: contentRef.current.scrollHeight + 'px' } : { height: '0px' }}
      >
        <div className='collapsible-area-content'>
          {props.children}
        </div>
      </div>
    </div>
  )

}

export default Collapsible;