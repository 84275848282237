import Header from "../common-components/Header";
import HeaderSpace from "../common-components/HeaderSpace";
import PageSection from "../common-components/PageSection";
import StroopWafel from "../common-components/StroopWafel";

function Contact(props)
{

  const narrow = props.narrow;

  return (
    
    <div className='Contact'>

      <Header transparent={false} narrow={narrow} setNarrowMenuOpen={props.setNarrowMenuOpen} />
      <HeaderSpace />

      <div className='page-container'>

        <PageSection title='Contact Us' centerContent={true}>
          <p>
            Have any questions about us or our shows?
            Feel free to get in touch!
          </p>
          <p>
            Instagram: <a href="https://instagram.com/darkslatetheatre">@darkslatetheatre</a>
          </p>
          <p>
            Email: <a href="mailto:darkslatetheatre@gmail.com">darkslatetheatre@gmail.com</a>
          </p>
        </PageSection>

      </div>

    </div>

  )

}

export default Contact;