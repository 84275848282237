import { useEffect, useState } from "react";
import Header from "../common-components/Header";
import textLogo from '../media/dark-slate-logo/dark-slate-text-logo.svg';
import narrowBackground from '../media/backgrounds/narrow-background.png';
import PageSection from "../common-components/PageSection";
import slateData from '../data/slate.json';

import aboutImage from '../media/dark-slate-logo/dark-slate-logo.svg';
import upcomingIcon from '../media/show-icons/_upcoming-icon.svg';
import StroopWafel from "../common-components/StroopWafel";
import Slate from "../common-components/Slate";
import Project from "../common-components/Project";


function Home(props) 
{

  /** The current pop-up Slate project */
  const [project, setProject] = useState(null);

  /** Track scroll position on the page. */
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
  };

  useEffect(() => {
      // Check for open project
      const splitUrl = window.location.pathname.split('/');
      console.log(splitUrl);
      if(splitUrl.length === 2 && splitUrl[1] !== '') {
        const projectKey = splitUrl[1];
        var projectData = null;
        slateData.forEach(p => {
          if (p.key === projectKey) { projectData = p; }
        })
        setProject(projectData);
      }
      
      // Add scroll listener
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const transparent = scrollPosition <= 600;
  const narrow = props.narrow;
  const narrowEnd = narrow? '-narrow' : '';

  /** Return contents */
  return (

    <div className={'home' + narrowEnd}>

      <Project project={project} setProject={setProject} narrow={narrow} />
      <Header transparent={transparent} narrow={narrow} setNarrowMenuOpen={props.setNarrowMenuOpen} />
      <StroopWafel autoOpen={true} />


      <div className={'home-text-logo' + narrowEnd}>
        {narrow? (
          <img src={narrowBackground} width='100%' alt='The Dark Slate' />
        ) : (
          <img src={textLogo} width='100px' alt='The Dark Slate' />
        )}
      </div>
      

      <div className='home-page-container'>
        
          <PageSection title='About The Dark Slate' image={aboutImage} narrow={narrow} >
            <p>
              Taking influence from film noir, german expressionism, and immersive theatre, 
              The Dark Slate Theatre Company aims to tell compelling stories of the 
              macabre, with a keen focus on design and world-building.
              <br/>
              <br/>
            </p>
            <button className='transparent-button' onClick={() => window.location.replace('/guild')}>
              Find out more
              <b>→</b>
            </button>
          </PageSection>

          <hr/>

          <PageSection title='Our Slate' centerContent={true}>
            <p className='sub-title-text'>Original creations from The Dark Slate Theatre Company</p>
            <Slate setProject={setProject} narrow={narrow} />
          </PageSection>

      </div>

    </div>

  )
}

export default Home;





function ShowCard(props) {

  if(props.announcementCard === true)
  {
    return (
      <div className='show-card'>
        <img src={upcomingIcon} width='200px' alt='show icon' />
        <h2 className='show-card-top-title'>Announcement</h2>
        <h1 className='show-card-main-title'>Coming Soon</h1>
      </div>
    );
  }

  return (
    <a
      className='show-card' 
      style={{...{color: '#eeeeee'},...{textDecoration: 'none'}}} 
      href={props.href} 
      target='_blank' 
      rel='noreferrer'
    >
      <img src={props.image} width='200px' alt='show icon' />
      <h2 className='show-card-top-title'>{props.topTitle}</h2>
      <h1 className='show-card-main-title'>{props.mainTitle}</h1>
      <p className='show-card-venue'>At {props.venue}</p>
    </a>
  )

}