import Header from "../common-components/Header";
import HeaderSpace from "../common-components/HeaderSpace";
import PageSection from "../common-components/PageSection";
import StroopWafel from "../common-components/StroopWafel";

import guildMembers from '../data/guild.json';


function Guild(props)
{

  const narrow = props.narrow;

  let members = guildMembers.map((member) => {
    if (member.hide) return null;
    return (
      <GuildMember key={member.name} member={member} narrow={narrow} />
    )
  })

  return (
    
    <div className='Guild'>

      <Header transparent={false} narrow={narrow} setNarrowMenuOpen={props.setNarrowMenuOpen} />
      <HeaderSpace />
      <StroopWafel autoOpen={false} />

      <div className='page-container'>

        <PageSection title='The Guild' centerContent={true}>
          <p>
            The Dark Slate Theatre Company is run by our Guild.<br/>
            They're the team of individuals responsible for the organisation and production of our shows.<br/>
            Each Guild member specialises in bringing something new to the dark slate. Meet them below!
          </p>
          <hr/>
          <div className='guild-member-section'>
            {members}
          </div>
        </PageSection>

      </div>

    </div>

  )

}

export default Guild;



function GuildMember(props)
{
  const x = props.member;
  const narrowEnd = props.narrow? '-narrow' : '';

  return (
    <div className={'guild-member' + narrowEnd}>
      <img className='guild-member-headshot' src={'./headshots/' + x.headshot} alt='headshot' />
      <div className='guild-member-details'>
        <h1 className='guild-member-name'>{x.name}</h1>
        <p className='guild-member-role'>{x.role}</p>
        <p className='guild-member-bio'>
          {x.bio}
        </p>
        {x.link === undefined? null : (
          <p className='guild-member-bio' style={{marginTop: '0'}}>
            <a href={x.link} target='_blank' rel='noreferrer'>{x.link_title} →</a>
          </p>
        )}
      </div>
    </div>
  )
}