import projects from '../data/slate.json';

function Slate(props) {

  const { setProject, narrow } = props;

  let slate = projects.map((project) => {
    return (
      <div 
        className='show-card' 
        key={project.title} 
        onClick={() => {
          setProject(project);
          /* 
            If the screen is narrow, then we want to change the URL in a way that
            triggers a re-render (location.replace), so the user is directed to the dedicated ProjectPage.
            Otherwise, we don't need to re-render the page, so we can change the URL in a way
            that doesn't affect the render (history.pushstate).
          */
          if (narrow) { window.location.replace('/' + project.key) }
          else        { window.history.pushState(null, '', '/' + project.key); }
        }}
      >
        <img src={'./projects/icons/' + project.media.image_key + '.png'} width='200px' alt='show icon' />
        <div className='show-card-details'>
          <h1 className='show-card-main-title'>{project.title}</h1>
          <p className='show-card-venue'>{project.venue}</p>
          <p className='show-card-click-for-more'>CLICK FOR MORE</p>
        </div>
      </div>
    )
  })

  return (
    <div className='show-card-container'>
      {slate}
    </div>
  )

}

export default Slate;