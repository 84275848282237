import { useState } from 'react';
import options from '../data/stroopwafel.json'; 


function StroopWafel(props) {

  const [open, setOpen] = useState(false);
  const [first, setFirst] = useState(true);

  // StroopWafel is activated/deactivated from the stroopwafel.json file
  if(options.active === false) return null;

  if(first && props.autoOpen) {
    setTimeout(function(){
      setFirst(false);
      setOpen(true);
    }, 200);
  }

  let buttons = options.buttons.map((button) => {
    return (
      <a href={button.link} target='_blank' rel='noreferrer' className='stroopwafel-option-button'>
        <p>
          {button.title}
        </p>
      </a>
    )
  })

  return (

    <div className={'stroopwafel-container-' + (open? 'open' : 'closed')} 
    onClick={(event) => {
      if(event.target === event.currentTarget) {
        if(open) setOpen(false);
      }
    }}>

      <div className={'stroopwafel-' + (open? 'open' : 'closed')} onClick={() => {
        if(!open) setOpen(true);
      }}>

        <h2>{options.title}</h2>

        {open? (

          <div className='stroopwafel-open-options'>
            <p>{options.subtitle}</p>
            <hr />
            {buttons}
          </div>

        ) : null}

      </div>
    </div>

  )

}

export default StroopWafel;