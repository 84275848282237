import { faBars } from '@fortawesome/free-solid-svg-icons';
import darkSlateLogo from '../media/dark-slate-logo/dark-slate-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Header(props)
{

  const transparent = (props.transparent === undefined? false : props.transparent);
  const transparentClassEnd = transparent? '-transparent' : '';

  const narrow = (props.narrow === undefined? false : props.narrow);

  return (

    <div className={'header' + transparentClassEnd}>

      <div>
        <img className={'header-logo' + transparentClassEnd} src={darkSlateLogo} alt='The Dark Slate' width='75px' />
        {narrow? null : (
          <div className='header-title'>
            <h4>The</h4>
            <h1>Dark Slate</h1>
          </div>
        )}
      </div>

      {narrow? (
        <div className='header-title-narrow'>
          <h2>Dark Slate</h2>
        </div>
      ) : null}

      {narrow? (
        <div className='header-button-set-narrow'>
          <button>
            <FontAwesomeIcon icon={faBars} onClick={() => {
              console.log(props.setNarrowMenuOpen)
                props.setNarrowMenuOpen(true)
              }
            } />
          </button>
        </div>
      ) : (
        <div className='header-button-set'>
          <PageButton index={0} route='/'>            Home            </PageButton>
          <PageButton index={1} route='/guild'>       Guild           </PageButton>
          <PageButton index={2} route='/contact'>     Contact         </PageButton>
        </div>
      )}

    </div>

  )

}

export default Header;



function PageButton(props) {
  return (
    <button 
      style={{animationDelay: (0.1 + (props.index * 0.05)).toString() + 's'}}
      onClick={() => window.location.replace(props.route)}
    >
      {props.children}
    </button>
  )
}