import { faCross, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NarrowNavMenu(props) {

  return (
    <div className='narrow-nav-menu-container'>
      <div className='narrow-nav-menu'>
        <div style={{...{display: 'flex'},...{width: '100%'},...{justifyContent: 'end'}}}>
          <FontAwesomeIcon icon={faX} style={{fontSize: '12px'}} onClick={() => props.setNarrowMenuOpen(false)} />
        </div>
        <PageButton index={0} route='/'>            Home            </PageButton>
        <PageButton index={1} route='/guild'>       Guild           </PageButton>
        <PageButton index={2} route='/contact'>     Contact         </PageButton>
        <div style={{height: '12px'}} />
      </div>
    </div>
  );

}

export default NarrowNavMenu;


function PageButton(props) {
  return (
    <button 
      style={{animationDelay: (0.1 + (props.index * 0.05)).toString() + 's'}}
      onClick={() => window.location.replace(props.route)}
    >
      {props.children}
    </button>
  )
}