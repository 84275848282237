import Collapsible from "./Collapsible";
import ImageSlider from "./ImageSlider";

function Project(props) {

  const { project, setProject, narrow } = props;
  if (project === null) return null;

  const imageUrls = project.media.gallery.map((fileName) => {return ("./projects/galleries/" + project.media.image_key + "/" + fileName)})

  return (
    <div 
      className={'project-container' + (narrow? '-narrow':'')}
      onClick={(e) => { 
        if (e.target === e.currentTarget) {
          setProject(null); 
          window.history.pushState(null, '', '/');
        }
      }}
    >
      <div className='project'>

        <img className='project-header-image' src={'./projects/headers/' + project.media.image_key + '.png'} alt='Project header' />
        <h1 className='project-title'>{project.title}</h1>
        <p className='project-data'>{project.performances.year} | {project.genre} | {project.venue}</p>
        <hr/>

        {
          project.actions.length > 0 && 
          <div>
            <div className='project-action-container'>
              {project.actions.map((action) => {
                return <button 
                        className='project-action' 
                        style={{width: (100 / project.actions.length) + '%'}}
                        key={action.label}
                        onClick={() => { window.open(action.href, '_blank'); }}
                      >{action.label.toLowerCase()}</button>
              })}
            </div>
            <hr style={{width: '100%'}}/>
          </div>
        }

        <p className='project-tagline'>{project.tagline}</p>
        {project.description.map((paragraph, index) => {
          return <p className='project-description' key={index}>{paragraph}</p>
        })}
        <hr/>

        <Collapsible label='Production Details'>
          <div className='project-production-details'>
            <div className='project-production-detail'>
                <div className='project-production-detail-label'> Playwright </div>
                <div className='project-production-detail-value'> {project.playwright} </div>
              </div>
              <div className='project-production-detail'>
                <div className='project-production-detail-label'> Runtime </div>
                <div className='project-production-detail-value'> {project.runtime} </div>
              </div>
              <div className='project-production-detail'>
                <div className='project-production-detail-label'> Performed </div>
                <div className='project-production-detail-value'> {project.performances.start_date} - {project.performances.end_date} {project.performances.end_month} {project.performances.year} </div>
              </div>
              <div className='project-production-detail'>
                <div className='project-production-detail-label'> Venue </div>
                <div className='project-production-detail-value'> {project.venue} </div>
              </div>
          </div>
        </Collapsible>
        {
          project.reviews.audience.length > 0 &&
          <Collapsible label='Audience Reviews'>
            {project.reviews.audience.map((review) => {
              return(
                <div className='project-audience-review' key={review}>
                  "{review}"
                </div>
              )
            })}
          </Collapsible>
        }
        {
          project.company.length > 0 && 
          <Collapsible label='Cast & Crew'>
            {project.company.map((group) => {
              return (
                <div className='project-company-group' key={group.label}>
                  <div className='project-company-group-label'>{group.label}</div>
                  {group.members.map((member) => {
                      return (
                        <div className='project-company-member' key={member.name}>
                          <div className='project-company-member-name'>{member.name}</div>
                          <div className='project-company-member-role'>
                            {member.roles !== undefined
                              ? member.roles.map((role) => {return <div key={role}>{role}</div>})
                              : <div>{member.role}</div>
                            }
                          </div>
                        </div>
                      )
                  })}
                </div>
              )
            })}
          </Collapsible>
        }
        <hr/>

        {
          project.media.gallery.length > 0 &&
          <div><ImageSlider urls={imageUrls} /></div>
        }

        <br/>
      </div>
    </div>
  )

}


export default Project;