import { useState } from "react";


function ImageSlider(props) {

  const { urls } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className='image-slider-container'>
      <div className='image-slider-selected'>
        <img src={urls[activeIndex]} width='100%' alt='carousel image'/>
      </div>
      <div className='image-slider-gallery'>
        {urls.map((src, index) => {
          return <img 
                    className='image-slider-gallery-image' 
                    style={{...{borderColor: (activeIndex === index) ? '#999999' : '#505050'},
                            ...{filter: (activeIndex === index) && 'brightness(1)'}}}
                    src={src} alt={'carousel gallery ' + index} 
                    key={src} 
                    onClick={() => {setActiveIndex(index)}} 
                  />
        })}
      </div>
    </div>
  )

}

export default ImageSlider;