import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import './App.css';
import { useEffect, useState } from 'react';
import Guild from './pages/Guild';
import Contact from './pages/Contact';
import NarrowNavMenu from './common-components/NarrowNavMenu';
import MobileProjectPage from './pages/MobileProjectPage';


function App() {

  /** Keeps track of whether the mobile menu is open */
  const [narrowMenuOpen, setNarrowMenuOpen] = useState(false);

  /** Track width of the page */
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  const narrow = width <= 450;

  /** Return page contents */
  return (
    <div className="App">
      {
        narrowMenuOpen? (
          <NarrowNavMenu setNarrowMenuOpen={setNarrowMenuOpen} />
        ) : null
      }
      <BrowserRouter>
        <Routes>
          <Route exact path='/'   element={ <Home narrow={narrow} setNarrowMenuOpen={setNarrowMenuOpen}    />} />
          <Route path='/*'        element={ narrow
                                            ? <MobileProjectPage setNarrowMenuOpen={setNarrowMenuOpen} /> 
                                            : <Home narrow={narrow} setNarrowMenuOpen={setNarrowMenuOpen} />
                                          }                                                                    />
          <Route path='/guild'    element={ <Guild narrow={narrow} setNarrowMenuOpen={setNarrowMenuOpen}   />} />
          <Route path='/contact'  element={ <Contact narrow={narrow} setNarrowMenuOpen={setNarrowMenuOpen} />} />
        </Routes>
      </BrowserRouter>
      
      <Footer />
    </div>
  );
}

export default App;



function Footer() {
  return (

    <div className='footer-container'>
      <hr/>
      <div>
        <p className='footer-text'>Website designed and developed by Thomas McCarthy</p>
      </div>
    </div>

  )
}