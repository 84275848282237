
function PageSection(props)
{

  const narrow = props.narrow;

  const title = props.title;
  const image = props.image;

  const firstColumnWidth = ( (image === undefined || narrow === true)? '100%' : '67.5%');
  const secondColumnWidth = ( (narrow)? '100%' : '27.5%');
  const contentAlignment = (props.centerContent === true? 'center' : 'start');

  return (
    <div className='page-section' style={{gap: narrow && '30px'}}>

      <div className='page-section-column' style={{...{width: firstColumnWidth},...{alignItems: contentAlignment},...{textAlign: contentAlignment}}}>
        <h1 className='page-section-title'>{title}</h1>
        {props.children}
      </div>

      {image !== undefined? (
        <div className='page-section-column' style={{...{width: secondColumnWidth},...{alignItems: (props.narrow ? 'center' : 'start')}}}>
          <img className='page-section-image' src={image} style={{opacity: narrow? '0.2' : '1'}} width={narrow? '50%' : '100%'} alt='page section' />
        </div>
      ) : null}

    </div>
  )

}


export default PageSection;