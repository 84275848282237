import { useEffect, useState } from "react";
import slateData from '../data/slate.json';
import Project from "../common-components/Project";
import Header from "../common-components/Header";

function MobileProjectPage(props) {

  const { setNarrowMenuOpen } = props;

  /** The current selected Slate project */
  const [project, setProject] = useState(null);

  useEffect(() => {

      // Check for open project
      const splitUrl = window.location.pathname.split('/');

      if(splitUrl.length === 2 && splitUrl[1] !== '') {
        const projectKey = splitUrl[1];
        var projectData = null;
        slateData.forEach(p => {
          if (p.key === projectKey) { projectData = p; }
        })
        if (projectData !== null) { setProject(projectData); }
        else { window.location.replace('/'); }
      }
      else {
        window.location.replace('/');
      }

  }, []);

  return (
    <div>
      <Header transparent={false} narrow={true} setNarrowMenuOpen={props.setNarrowMenuOpen} />
      <Project project={project} setProject={setProject} narrow={true} />
    </div>
  )

}

export default MobileProjectPage;